.root {
    width: 100%;
}

.dashboardCards {
    display: flex;
    flex-flow: row nowrap;
    justify-content: stretch;
    margin: 16px -8px
}

