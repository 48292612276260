.metricCard {
    display: flex;
    background: #ffffff;
    border-radius: 8px;
    min-height: 92px;
    margin: 0px 8px;
    min-width: 175px;
    /* max-width: 400px; */
    flex-basis: 0;
    flex-grow: 1;
    box-shadow: var(--shadow4dp);
}

.metricCardContent {
    margin: 16px;
    position: relative;
    width: 100%;
    /* height: 100%; */
}

.metricCardContent p {
    margin-bottom: 0px;
}

.title {
    font-size: 13px;
    font-weight: 500;
    color: var(--grey50);
}

.number {
    font-size: 24px;
    font-weight: 600;
    color: var(--grey70);
}

.description {
    font-size: 13px;
    font-weight: 500;
}

.description div {
    display: flex;
    align-items: center;
    position: relative;
    font-size: 12px;
}

.description p {
    white-space: nowrap;
}

.logoContainer {
    position: absolute;
    top: 0;
    right: 0;
    width: 42px;
    height: 42px;
    border-radius: 50%;
}

.logo {
    margin-right: 4px;
}