@import "../components/@setproduct-ui/styles/setproduct.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";

#root {
    min-width: 100%;
    min-height: 100%;
}

.sidebar {
    background: #ffffff;
    padding: 16px;
    width: 216px;
    box-shadow: var(--shadow4dp);
}

.content {
    background: linear-gradient(0, #FFFFFF 60%, #1C2D59 40%);
    padding: 0px 24px;
}

.navbar {
    background: #1C2D59;
    padding: 0px 24px;
}

.navbar-content {
    background: #1C2D59;
    height: 64px;
}

.navbar-header {
    font-size: 24px;
    color: #ffffff;
    font-weight: 600;
}

.splash-screen-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.splash-screen-content {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.splash-screen-content img {
    width: 96px;
    height: auto;
}