.tableHeaderContainer {
    background: #fff;
    padding: 12px 7px;
    border-radius: 8px;
    box-shadow: var(--shadow4dp);
}

.label {
    font-family: var(--inter);
    font-weight: bold;
    margin-bottom: 8px;
}

.roundBox {
    border-radius: .8em;
}

.menu {
    position: absolute;
    z-index: 10;
    right: 21px;
    top: 0;
}

.tableContainer {
    background: #fff;
    border-radius: 8px;
    box-shadow: var(--shadow4dp);
    min-height: 80%;
}

.tableToolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 22px;
    gap: 24px;
}

.tableToolbarScrollable {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 22px;
    gap: 24px;
    overflow-x: auto;
}

.tableTitle {
    font-family: var(--inter);
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 158%;
    letter-spacing: 0.004em;
    margin: 0;
    white-space: nowrap;
}

.searchInput {
    width: 191px;
    margin-right: 15px;
    height: 42px;
}

.mainButton {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    white-space: nowrap;
}
