.container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.metricContainer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: stretch;
	overflow: hidden;
}

.arrowButton {
	color: #fff;
}