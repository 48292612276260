/* BUTTONS TYPES*/

/*--DEFAULT--*/
.default {
  text-transform: capitalize;
}

.default > li::after {
  margin: 0 0.625rem;
  width: 1.25rem;
  height: 1.25rem;
}

.default span[class*="icon"] {
  margin-right: 0.5rem;
}

.default span[class*="breadcrumbs-collapsed"]::before {
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  margin-right: auto;
}
.default span[class*="breadcrumbs-collapsed"] {
  margin-right: 0;
  width: 2rem;
  height: 1.25rem;
  border-radius: 0.25rem;
  padding: 0;
}
.default a[class*="breadcrumb"] {
  padding: 0.125rem 0.5rem;
  border-radius: 0.25rem;
}
