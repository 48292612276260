.searchWrapper {
  background: #fff;
  padding: 15px 10px;
  margin-bottom: 20px;
}

.label {
  font-family: var(--inter);
  font-weight: bold;
  margin-bottom: 8px;
}

.formLabel {
  font-family: var(--inter);
  font-size: var(--font_size_body_small);
  color: var(--grey50);
  margin-bottom: 3px;
}

.roundBox {
  border-radius: .8em;
}

.mainButton {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.menu {
  position: absolute;
  z-index: 10;
  right: 45px;
  top: 0;
}

.select {
  margin-bottom: 20px;
}

.flexCell {
  display: flex;
  align-items: flex-end;
} 

.tableContainer {
  background: #fff;
  border-radius: 8px;
  box-shadow: var(--shadow4dp);
}

.tableToolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 22px;
}

.tableTitle {
  font-family: var(--inter);
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 158%;
  letter-spacing: 0.004em;
  margin: 0;
}

.searchInput {
  width: 191px;
}
