.sidebarContainer a {
  text-decoration: none;
  color: var(--grey60);
}

.sidebarHeaderContainer {
  padding: 2px 4px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sidebarLogo {
  margin-right: 4px;
  color: var(--blue100);
}

.sidebarLogo img {
  width: 28px;
  height: auto;
}

.sidebarLogoTitle {
  color: var(--grey70);
  font-size: 20px;
  font-weight: 600;
}

.sidebarItems {
  margin-top: 48px;
}

.sidebarItem {
  /* margin: 20px 0px; */
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 14px 10px;
  border-radius: 8px;
}
.sidebarItem span {
  font-size: 14px;
  font-weight: 500;
  color: var(--grey60);
}

.sidebarItem.active {
  background: var(--blue0);
}

.sidebarItemIcon {
  margin-right: 16px;
}

.sidebarItemSubitem {
  margin-left: 34px;
}