/*MORE ICON COLOR*/
.primary span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%232979ff'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
.success span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%2343a047'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
.warning span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%23d6981b'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
.danger span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%23F44336'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
.default span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%235A5B6A'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
.primary_alt span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%235c6bc0'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
.success_alt span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%23069697'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
.warning_alt span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%23fb8200'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
.danger_alt span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%23ec407a'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
/*CHEVRON ICON COLOR*/
.primary > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%232979FF'/></svg>");
}
.success > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%2343a047'/></svg>");
}
.warning > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%23d6981b'/></svg>");
}
.danger > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%23F44336'/></svg>");
}
.default > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%235a5b6a'/></svg>");
}
.primary_alt > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%235c6bc0'/></svg>");
}
.success_alt > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%23069697'/></svg>");
}
.warning_alt > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%23fb8200'/></svg>");
}
.danger_alt > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%23ec407a'/></svg>");
}
/*--------*/

/*SMOOTH STYLE*/
.smooth {
  color: var(--color100);
}
.smooth span[class*="breadcrumbs-collapsed"] {
  background: var(--color30);
  color: var(--color100);
}
.smooth a[class*="breadcrumb"] {
  color: var(--color80);
}
.smooth a[class*="breadcrumb"] > span[class*="icon"] {
  color: var(--color30);
}
.smooth a[class*="breadcrumb"]:hover {
  background: var(--color5);
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
.smooth a[class*="breadcrumb"]:hover > span[class*="icon"] {
  color: var(--color50);
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}

/* END STYLE */
.popover div[class*="popover-content"] a[class*="menu-item"]:hover {
  background-color: var(--color5);
}
.popover div[class*="popover-arrow"] {
  display: none;
}
